var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.receita.id},[_c('div',{staticClass:"detalhes"},[_c('div',{staticClass:"detalheItem"},[_vm._m(0),_vm._v(" "+_vm._s(_vm.receita.codigo)+" "),_c('small',[_c('small',[_vm._v(" ID: "+_vm._s(_vm.receita.id)+" ")])])]),_c('div',{staticClass:"detalheItem"},[_vm._m(1),_vm._v(" "+_vm._s(_vm.receita.usuario)+" ")]),_c('div',{staticClass:"detalheItem"},[_vm._m(2),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.receita.created_at,"DD/MM/YYYY HH:mm"))+" ")]),_c('div',{staticClass:"detalheItem"},[_vm._m(3),_vm._v(" "+_vm._s(_vm.receita.horario)+" ")]),_c('div',{staticClass:"detalheItem"},[_vm._m(4),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.receita.tipo,"YYYY/MM/DD HH:mm"))+" ")]),_c('div',{staticClass:"detalheItem"},[_vm._m(5),_vm._v(" "+_vm._s(_vm.receita.medicoNome)+" ")]),_c('div',{staticClass:"detalheItem"},[_vm._m(6),_vm._v(" "+_vm._s(_vm.receita.especialidade)+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-code"}),_vm._v(" Código ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-user"}),_vm._v(" Usuário Cadastro")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-calendar"}),_vm._v(" Data ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-clock"}),_vm._v(" Atendimento")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-calendar"}),_vm._v(" Tipo ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-user-nurse"}),_vm._v(" Médico")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-stethoscope"}),_vm._v(" Especialidade")])
}]

export { render, staticRenderFns }