<template>
  <div :key="objeto.id">
    <div class="detalhes">
      <div class="detalheItem">
        <label> <i class="fas fa-calendar"></i> Data Solicitação </label>
        {{ objeto.dt_encaminhamento | moment("YYYY/MM/DD ") }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user-nurse"></i> Médico Solicitou</label>
        {{ objeto.medicoAtendimento }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-stethoscope"></i> Especialidade Solicitada</label>
        {{ objeto.especialidadeEncaminhado }}
      </div>
 
      <div class="detalheItem" v-if="objeto.medicoEncaminhado">
        <label> <i class="fas fa-user"></i> Médico Solicitado</label>
        {{ objeto.medicoEncaminhado }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    objeto: Object,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/paciente.scss";
</style>
