<template>
  <div :key="consulta.id">
    <!-- {{consulta}} -->
    <div class="detalhes">
      <div class="detalheItem">
        <label> <i class="fas fa-calendar"></i> Data </label>
        {{ consulta.data | moment("DD/MM/YYYY HH:mm") }}
      </div>
      <!-- <div class=""> -->
        <!-- <h4 class="text-center">Itens</h4>
        <b-table show-empty small outlined class="" :fields="camposItens" :items="consulta.itens">
          <template #cell(data_consulta)="{item}">
            {{item.data_consulta | moment('DD/MM/YYYY ')}}
          </template>
          <template #cell(vl_total)="{item}">
            {{item.vl_total | currencyWithMask}}
          </template>
        </b-table>
      </div> -->
      <div class="detalheItem">
        <label> <i class="fas fa-user-nurse"></i> Médico</label>
        {{ consulta.itens[0].fornecedor }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-align-center"></i> Descrição</label>
        {{ consulta.itens[0].descricao }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user"></i> Usuário</label>
        {{ consulta.usuario }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-dollar-sign"></i> Valor Total</label>
        {{ consulta.vl_total | currencyWithMask }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    consulta: Object,
  },
  data() {
    return {
      camposItens: [
        { key: "descricao", label: "Descrição", class: 'text-left' },
        { key: "vl_total", label: "Valor Total", class: 'text-center' },
        { key: "data_consulta", label: "Data Consulta", class: 'text-center' },
        { key: "fornecedor", label: "Médico", class: 'text-center' },
        { key: "actions", label: "" },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/paciente.scss";
</style>
