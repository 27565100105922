<template>
  <div :key="receita.id">
    <div class="detalhes">
      <div class="detalheItem">
        <label> <i class="fas fa-code"></i> Código </label>
        {{ receita.codigo }} 
        <small>
          <small>
            ID: {{receita.id}}
          </small>
        </small>
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user"></i> Usuário Cadastro</label>
        {{ receita.usuario }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-calendar"></i> Data </label>
        {{ receita.created_at | moment("DD/MM/YYYY HH:mm") }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-clock"></i> Atendimento</label>
        {{ receita.horario }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-calendar"></i> Tipo </label>
        {{ receita.tipo | moment("YYYY/MM/DD HH:mm") }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user-nurse"></i> Médico</label>
        {{ receita.medicoNome }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-stethoscope"></i> Especialidade</label>
        {{ receita.especialidade }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    receita: Object,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/paciente.scss";
</style>
