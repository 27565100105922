<template>
  <div class="item">
    <div class="icone">
      <img :src="imagem[tipo]" />
      
      {{ tipo }}
    </div>
  

    <paciente-item-detalhe-atestado
      :key="`atestado_${objeto.id}`"
      v-if="tipo == 'Atestado'"
      :atestado="objeto"
    />

    <paciente-item-detalhe-anamnese
      v-if="tipo == 'Anamnese'"
      :key="`anamnese_${objeto.id}`"
      :anamnese="objeto"
      @openCard="$emit('openCard', $event)"
      @preencherAnamnese="$emit('preencherAnamnese', $event)"
    />

    <paciente-item-detalhe-exames
      v-if="tipo == 'Exame'"
      :key="`exame_${objeto.id}`"
      :exame="objeto"
    />
    <paciente-item-detalhe-consultas
      :consulta="objeto"
      v-if="tipo == 'Consulta'"
      :key="`consulta_${objeto.id}`"
    ></paciente-item-detalhe-consultas>

    <paciente-item-detalhe-receitas
      v-if="tipo == 'Receita'"
      :key="`receita_${objeto.id}`"
      :receita="objeto"
    />
    <paciente-item-detalhe-encaminhamentos
      v-if="tipo == 'Encaminhamento'"
      :key="`encaminhamento_${objeto.id}`"
      :objeto="objeto"
    />
    <b-btn v-if="btExcluir" @click.stop="()=>{excluir(objeto.id); $event.preventDefault();}" variant="danger" style="align-self:flex-start; margin-right:5px; margin-top:5px;">
      <b-icon-trash /> Excluir
    </b-btn>
  </div>
</template>

<script>
import Atestadoimg from '@/assets/img/icones/menu/icons8_user_clock_40px.png'
import AnamneseImg from "@/assets/img/icones/menu/treatment_40px.png"
import ExameImg from "@/assets/img/icones/menu/icons8_heart_with_pulse_40px.png"
import ReceitaImg from "@/assets/img/icones/menu/icons8_autograph_40px.png"
import ConsultaImg from "@/assets/img/icones/menu/stethoscope_40px.png"
import EncaminhamentoImg from "@/assets/img/icones/menu/data_transfer_40px.png"

import PacienteItemDetalheAtestado from "./TiposPacienteItem/PacienteItemDetalheAtestado.vue";
import PacienteItemDetalheAnamnese from "./TiposPacienteItem/PacienteItemDetalheAnamnese.vue";
import PacienteItemDetalheExames from "./TiposPacienteItem/PacienteItemDetalheExames.vue";
import PacienteItemDetalheReceitas from "./TiposPacienteItem/PacienteItemDetalheReceitas.vue";
import PacienteItemDetalheEncaminhamentos from "./TiposPacienteItem/PacienteItemDetalheEncaminhamentos.vue";
import PacienteItemDetalheConsultas from "./TiposPacienteItem/PacienteItemDetalheConsultas.vue";


export default {
  components: {
    PacienteItemDetalheAtestado,
    PacienteItemDetalheAnamnese,
    PacienteItemDetalheExames,
    PacienteItemDetalheReceitas,
    PacienteItemDetalheConsultas,
    PacienteItemDetalheEncaminhamentos
  },
  props: {
    tipo: String,
    objeto: Object,
    btExcluir:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imagem: {
        'Atestado': Atestadoimg,
        'Anamnese': AnamneseImg,
        'Exame': ExameImg,
        'Receita': ReceitaImg,
        'Consulta': ConsultaImg,
        'Encaminhamento': EncaminhamentoImg
      },
      // component: {
      //     'Atestado': PacienteItemDetalheAtestado,
      //   'Anamnese': PacienteItemDetalheAnamnese,
      //   'Exame': PacienteItemDetalheExames,
      //   'Receita': PacienteItemDetalheReceitas,
      //   'Consulta': ConsultaImg,
      // }
    };
  },
  methods: {
    icone() {
      return this.tipo == "Atestado" ? "icons8_user_clock_40px.png" : "";
    },
    excluir(id) {
      console.log("excluid", id);
      this.$emit("excluir",id);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
