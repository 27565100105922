<template>
  <div :key="exame.id">
    <div class="detalhes">
      <div class="detalheItem">
        <label> <i class="fas fa-calendar"></i> Data </label>
        {{ exame.data | moment("DD/MM/YYYY HH:mm") }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user-nurse"></i> Médico</label>
        {{ exame.medicoNome }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-stethoscope"></i> Especialidade</label>
        {{ exame.especialidade }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user"></i> Usuário</label>
        {{ exame.usuario }}
      </div>
      <div v-if="exame.dt_utlizacao" class="used"></div>
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    exame: Object,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/paciente.scss";
</style>
