<template>
  <div class="anamnese">

    <div class="detalhes" :key="anamnese.id" @click="$emit('openCard', anamnese)">
     
      <div class="detalheItem">
        <label> <i class="fas fa-calendar"></i> Data </label>
        {{ anamnese.created_at | moment("YYYY/MM/DD HH:mm") }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user-nurse"></i> Médico</label>
        {{ anamnese.medicoNome }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-stethoscope"></i> Especialidade</label>
        {{ anamnese.especialidade }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user"></i> Usuário</label>
        {{ anamnese.usuarioSolicitou }}
      </div>

     
    </div>
     <div class="actions">
          <b-btn
  
          @click="openPreencherAnamnese"
          class="text-nowrap flex-grow-1 mx-1 float-left rounded "
        >
          <i class="fas fa-notes-medical"></i> {{anamnese.respostas.id?'Visualizar':'Preencher'}} Anamnese
        </b-btn>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    anamnese: Object,
  },
  methods:{
    openPreencherAnamnese(){
      this.$emit('preencherAnamnese', this.anamnese)
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/paciente.scss";
</style>
