<template>
  <div :key="atestado.id">
    <div class="detalhes">
      <div class="detalheItem">
        <label> <i class="fas fa-calendar"></i> Data de Criação</label>
        {{ atestado.created_at | moment("DD/MM/YYYY HH:mm") }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-calendar-day"></i> Qtd. Dias</label>
        {{ atestado.dias }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-calendar-alt"></i> Duração</label>
        De {{ atestado.periodo_de | moment("DD/MM/YYYY") }} até
        {{ atestado.periodo_ate | moment("DD/MM/YYYY") }}
      </div>
      <div class="detalheItem">
        <label> <i class="fas fa-user-nurse"></i> Médico</label>
        {{ atestado.medicoNome }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    atestado: Object,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/paciente.scss";
</style>
